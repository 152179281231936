import { FormStatus, FormValue, IOfflineForm, IOfflineFormFile, IOfflineFormFileRef } from './form-types'
import * as FormIDB from './idb'
import { SubmissionLocation } from '../_types/commons'
import { client } from '../_api-services/urls'

/**
 * Encapsulates functionality relating to files associated with offline forms
 */
class FormFile {
  /** The data about this file that can be messaged or stored */
  clonableData: IOfflineFormFile

  /** Private constructor  - external code should use createFrom... methods */
  constructor (clonableData: IOfflineFormFile) {
    this.clonableData = clonableData
  }

  /**
     * Create a new FormFile from a File object ( formData value )
     * @param file the File object to create this from
     * @param formKey the key to the OfflineForm key this file relates to
     * @returns a Formfile object
     */
  static createFromFile (file: File, formKey: string): FormFile {
    return new FormFile({
      key: crypto.randomUUID(),
      name: file.name,
      type: file.type,
      bits: file.slice(),
      formKey
    })
  }

  /**
     * Create a new FormFile from clonable data - that has been read from a message or storage
     * @param clonableData the data to create this from
     * @returns a Formfile object
     */
  static createFromClonable (clonableData: IOfflineFormFile): FormFile {
    return new FormFile(clonableData)
  }

  /** The unique key for this file */
  get key (): string {
    return this.clonableData.key
  }

  /** A preview of this file
     * - NOTE: creates object urls, which should be disposed of somehow
     * - NOTE only works for images so far!
     */
  get previewElement (): string {
    if (typeof this.clonableData.bits !== 'undefined') {
      const f = new File([this.clonableData.bits.slice()], this.clonableData.name)
      const previewUrl = URL.createObjectURL(f)
      return previewUrl
    }
    return ''
  }

  get previewUploadedFile (): string {
    return typeof this.clonableData.thumbnail !== 'undefined' ? this.clonableData.thumbnail.webp : ''
  }
}

/**
 * Encapsulates functionality relating to offilne forms
 */
class Submission {
  /** The data about this form that can be messaged or stored */
  clonableData: IOfflineForm
  /** The instantiated file objects ( from IOfflineFormFileRef objects found in clonableData. )
     * See this.ensureFiles
     */
  files: Map<string, FormFile> = new Map()

  /** Private constructor  - external code should use createFrom... methods */
  private constructor (clonableData: IOfflineForm) {
    this.clonableData = clonableData
  }

  /**
     *  Create a new unsaved form object
     * @param type - the form type
     * @returns a new Offlineform object
     */
  static createFromEmpty (type: string): Submission {
    return new Submission({
      key: crypto.randomUUID(),
      type,
      status: FormStatus.NEW,
      fields: {},
      locations: []
    })
  }

  /** Get all Submissions by type, excluding some if required */
  static async getAllByType (type: string, excludeKeys: string[] = []): Promise<Submission[]> {
    const records = await FormIDB.getFormsByType(type)
    return records
      .filter(r => !excludeKeys.includes(r.key))
      .map(this.createFromClonable)
  }

  /** Create a Submission from a key only - returns null if not in idb */
  static async createFromIDBKey (key: string): Promise<Submission | undefined> {
    const cloneableData = await FormIDB.getForm(key)
    if (cloneableData !== undefined) {
      return new Submission(cloneableData)
    }
  }

  /**
     * Create a new Form from clonable data - that has been read from a message or storage
     * @param clonableData the data to create this from
     * @returns an OfflineForm object
     */
  static createFromClonable (clonableData: IOfflineForm): Submission {
    return new Submission(clonableData)
  }

  /* All submission records which are pending */
  static async pendingSubmissionRecords (): Promise<IOfflineForm[]> {
    return await FormIDB.getAllByStatus(FormStatus.PENDING_SUBMIT)
  }

  /** unique key for this form */
  get key (): string {
    return this.clonableData.key
  }

  /** for convenience - will only work with a form with a name field! */
  get name (): string {
    const nameField = this.clonableData.fields.name
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return (nameField !== undefined) ? nameField.toString() : 'Unnamed'
  }

  /* the status of the submission */
  get status (): FormStatus {
    return this.clonableData.status
  }

  /** the status of the form e.g. new, pending, submitted etc. */
  set status (status: FormStatus) {
    this.clonableData.status = status
  }

  /** the type of the submission */
  get type (): string {
    return this.clonableData.type
  }

  /** the actual data contained in this form */
  get fields (): Record<string, any> {
    return this.clonableData.fields
  }

  /* the locations of the submission */
  set locations (locations: SubmissionLocation[]) {
    this.clonableData.locations = locations
  }

  get locations (): SubmissionLocation[] {
    return this.clonableData.locations
  }

  /** Edit the Form instance by applying an HTML form to it's fields */
  applyFormData (formData: FormData): void {
    this.clonableData.fields = {}
    for (const key of formData.keys()) {
      const values = formData.getAll(key)
      this.clonableData.fields[key] = values.length === 1
        ? this.getCloneableValue(values[0])
        : values.map((v) => this.getCloneableValue(v))
    }
  }

  /** Edit the Form instance by applying an HTML form to it's fields */
  applyFormJson (formData: object): void {
    this.clonableData.fields = {}
    for (const [key, value] of Object.entries(formData)) {
      if (typeof value === 'object' && value !== null) {
        Object.keys(value).forEach(secondaryKey => {
          this.clonableData.fields[secondaryKey] = value[secondaryKey]
        })
      } else {
        this.clonableData.fields[key] = value
      }
    }
  }

  replaceFiles (files: FormFile[]): void {
    const map: Map<string, FormFile> = files.reduce((acc, obj) => {
      acc.set(obj.key, obj)
      return acc
    }, new Map<string, FormFile>())
    this.files = map
  }

  /** Edit the form by replacing the data entirely
   * This is useful where we're using JSON directly
  */
  replaceData (formData: Record<string, any>): void {
    this.clonableData.fields = structuredClone(formData)
  }

  /** Transmutes FormDataEntryValues into cloneable objects (special handling for files) */
  getCloneableValue (val: FormDataEntryValue): FormValue {
    if (val instanceof File) {
      const file = FormFile.createFromFile(val, this.key)
      this.files.set(file.key, file)
      return {
        type: 'fileRef',
        key: file.key
      }
    } else {
      return val
    }
  }

  renderToForm (form: HTMLFormElement): void {
    form.querySelectorAll('input').forEach(
      (element) => { void setElementValueFromObj(element, this) }
    )
    form.querySelectorAll('textarea').forEach(
      (element) => { void setElementValueFromObj(element, this) }
    )
    form.querySelectorAll('select').forEach(
      (element) => { void setElementValueFromObj(element, this) }
    )
  }

  async saveToIdb (): Promise<void> {
    await FormIDB.saveForm(
      this.clonableData,
      Array.from(this.files.values()).map(
        ff => ff.clonableData
      )
    )
  }

  async updateFromIdb (): Promise<void> {
    const data = await FormIDB.getForm(this.key)
    if (data !== undefined) {
      this.clonableData = data
    }
  }

  async deleteFromIdb (): Promise<void> {
    await FormIDB.deleteForm(
      this.key,
      Array.from(this.files.keys())
    )
  }

  async deleteFilesFromIdbByFileKey (key: string): Promise<void> {
    await FormIDB.deleteFilesByFormKey(key)
  }

  async postToServer (): Promise<boolean> {
    const submission = {
      key: this.key,
      fields: this.clonableData.fields as Record<string, never>,
      form_type: this.type,
      locations: this.locations
    }
    const { error } = await client.POST('/api/submissions/create', { body: submission })
    if (typeof error !== 'undefined') throw new Error(error)
    return true
  }

  // async getFileElements (): Promise<Node[]> {
  //   await this.ensureFiles()
  //   return Array.from(this.files.values()).map(f => f.previewElement)
  // }

  /** ensure the file map is up to date */
  async ensureFiles (): Promise<void> {
    // check every field value
    for (const key in this.clonableData.fields) {
      // wrap single values in arrays for consistency
      const values: FormValue[] = this.clonableData.fields[key] instanceof Array
        ? this.clonableData.fields[key] as FormValue[]
        : [this.clonableData.fields[key] as FormValue]

      await Promise.all(
        values.map(async val => {
          // If we have a fileRef...
          const fileRef = (val as IOfflineFormFileRef)
          if (fileRef !== undefined && fileRef.type === 'fileRef' && fileRef.key.length > 0) {
            // with a key...
            if (!this.files.has(fileRef.key)) {
              // populate the internal file map having read from idb
              const file = await FormIDB.getFile(fileRef.key)
              this.files.set(
                fileRef.key,
                FormFile.createFromClonable(file)
              )
            }
          }
        })
      )
    }
  }
}
async function setElementValueFromObj (
  element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
  form: Submission | null): Promise<void> {
  const elName = element.getAttribute('name')
  if (elName != null && (form != null)) {
    const objValue = form.clonableData.fields[elName]
    if (element.type === 'radio')(element as HTMLInputElement).checked = (objValue as FormValue) === element.value
    else if (element.type === 'checkbox') (element as HTMLInputElement).checked = (objValue as FormValue[]).includes(`${element.value}`)
    else if (element.type !== 'file' && objValue !== undefined) element.value = (objValue as string).toString()
    // else if (element.type === 'file')element.after(...await form.getFileElements())
  }
}
export {
  Submission as OfflineForm,
  FormFile,
  FormStatus
}
export type {
  IOfflineFormFile,
  IOfflineForm
}

declare global {
  interface FormData {
    keys: () => Iterable<string>
  }
}
