import createClient, { defaultQuerySerializer, createFinalURL } from 'openapi-fetch'
import type { paths } from './openapi'
import { getCsrf } from '../_helpers/common.helpers'
const { MSW_BASE_URL } = import.meta.env

const DEFAULT_HEADERS = {
  'X-CSRFToken': getCsrf(),
  'Content-Type': 'application/json'
}

const DEFAULT_CACHE = 'cache'
const DEFAULT_TIMEOUT = 5000

const clientOps = {
  baseUrl: MSW_BASE_URL,
  headers: DEFAULT_HEADERS
}

const _client = createClient<paths>(clientOps)

/**
 * This code is copied from `openapi-fetch`. We're using it to generate
 * the same response from cache as we would get from an OpenAPI call.
 */
const parse = async (response: Response, parseAs: 'json' | 'stream' = 'json') => {
  // handle empty content
  // note: we return `{}` because we want user truthy checks for `.data` or `.error` to succeed
  if (
    response.status === 204 ||
    response.headers.get('Content-Length') === '0'
  ) {
    return response.ok ? { data: {}, response } : { error: {}, response }
  }

  // parse response (falling back to .text() when necessary)
  if (response.ok) {
    // if 'stream', skip parsing entirely
    if (parseAs === 'stream') {
      // fix for bun: bun consumes response.body, therefore clone before accessing
      // TODO: test this?
      return { data: response.clone().body, response }
    }
    const cloned = response.clone()
    return {
      data:
        typeof cloned[parseAs] === 'function'
          ? await cloned[parseAs]()
          : await cloned.text(),
      response
    }
  }

  // handle errors (always parse as .json() or .text())
  let error = {}
  try {
    error = await response.clone().json()
  } catch {
    error = await response.clone().text()
  }
  return { error, response }
}

// List of "urls" to cache timeouts
type cacheSettings = Array<{
  shouldCache: ({ url, options }: { url: Parameters<typeof _client['GET']>[0], options: Parameters<typeof _client['GET']>[1] }) => boolean
  timeout?: number
  cacheName?: string
}>

/**
 * A list of URLS to cache
 * shouldCache is a function taking the same params as client.GET
 * timeout is how long in ms to keep the cached item before deleting it
 */
const cacheSetting: cacheSettings = [{
  shouldCache: ({ url, options }) => url === '/api/landing_page/data',
  timeout: 30000
},
{
  shouldCache: ({ url, options }) => url === '/api/ida/usermunisipiu/',
  timeout: 5000
},
{
  shouldCache: ({ url, options }) => url === '/api/complaint/all',
  timeout: 20000
},
{
  shouldCache: ({ url, options }) => url === '/api/suku_profile/legacy-projects',
  timeout: 20000
},
{
  shouldCache: ({ url, options }) => url === '/api/ida/list-suco-translation',
  timeout: 20000
}, {
  shouldCache: ({ url, options }) => url.startsWith('/api/suku_profile/beneficiaries_per_sector'),
  timeout: 30000
}
]

const getCached = (client: typeof _client) => {
  const GET = async (...params: Parameters<typeof client['GET']>) => {
    // Check if this is a 'should cache' url
    const [url, options] = params
    const urlCacheSetting: cacheSettings[0] | undefined = cacheSetting.find(it => it.shouldCache({ url, options }))
    if (typeof urlCacheSetting === 'undefined') {
      // Not something we want to cache; return the normal 'GET' path
      // sourcery skip: inline-immediately-returned-variable
      const result = await client.GET(url, options)
      return result
    }
    const { cacheName = DEFAULT_CACHE, timeout = DEFAULT_TIMEOUT } = urlCacheSetting
    // Use the 'final' URL function to capture parameters
    // This ensures that we don't cache the same URL with different query params
    const finalURL = createFinalURL(url, {
      baseUrl: MSW_BASE_URL ?? '',
      params: options?.params ?? {},
      querySerializer: defaultQuerySerializer
    })
    // Check if it is in the cache. Return it if found.
    const responseFromCache = await (await caches.open(cacheName)).match(finalURL)
    // If it has been found in the cache, great! Return it.
    if (responseFromCache instanceof Response) {
      return parse(responseFromCache) as unknown as typeof client.GET<typeof url>
    }
    // It is something to cache but not yet in the cache. Fetch it and cache it.
    const { response, data, error } = await client.GET(url, options)
    if ((response instanceof Response) && response.ok) {
      await (await caches.open(cacheName)).put(finalURL, response.clone())
      setTimeout(() => {
        void caches.open(cacheName).then(async c => await c.delete(finalURL))
      }, timeout)
    } else if (response.status === 401) {
      // Remove cache if we get a 401
      void await (await caches.open(cacheName)).delete(finalURL)
    }
    return { response, data, error }
  }
  return { GET: GET as typeof _client['GET'] }
}

// For all operations; we also want to add the current CSRF token as header
// To do this we need to wrap the client.GET function

// const client = createClient()

const client = new Proxy(_client, {
  get (_, key: keyof typeof _client) {
    // We're creating a new Client because the value of our `X-CSRFToken` might change
    // It's not (yet) possible to have this kind of 'dynamic' key in the client class
    const newClient = createClient<paths>({
      baseUrl: MSW_BASE_URL,
      headers: {
        'X-CSRFToken': getCsrf()
      }
    })
    if (key === 'GET') {
      return getCached(newClient)[key]
    }
    return newClient[key]
  }
})
export { client, cacheSetting }
