import { SubmissionLocation } from '../_types/commons'

/** The status of an offline form */
enum FormStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  PENDING_SUBMIT = 'PENDING',
  SUBMITTED = 'SUBMITTED',
}

/** The critical data relating to a file associated with an offline form
 * Must support structuredClone to allow storage and messaging
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
*/

interface FileThumbnail {
  jpg: string
  webp: string
}
interface IOfflineFormFile {
  key: string
  formKey: string
  name: string
  type?: string
  bits?: Blob
  isUploaded?: boolean
  thumbnail?: FileThumbnail
  file?: string
}

/** A reference to an offline form file, to be stored in the main form file data */
interface IOfflineFormFileRef {
  type: 'fileRef'
  key: string
}

/** Values we can store in OfflineForm field values (or value arrays) */
type FormValue = string | File | IOfflineFormFileRef

/** The critical data relating to a file associated with an offline form
 * Must support structuredClone to allow storage and messaging
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm
*/
interface IOfflineForm {
  key: string
  type: string
  status: FormStatus
  created?: Date
  updated?: Date
  fields: Record<string, FormValue | FormValue[]>
  locations: SubmissionLocation[]
}

export {
  FormStatus
}
export type {
  IOfflineFormFile,
  IOfflineForm,
  FormValue,
  IOfflineFormFileRef
}
