import format from 'date-fns/format'

type month = number
type year = number

/**
 * Sorts an array of objects by year and month in descending order.
 * This is important as an input into mapByYearMonth as Map remembers insertion order
 */
const sortByYearMonth = <T extends Partial<{ year: number, month: number }>>(instances: T[], reverse = false): T[] => {
  return instances.sort((a, b) => {
    const yearComparison = reverse ? Number(a.year) - Number(b.year) : Number(b.year) - Number(a.year)
    if (yearComparison !== 0) {
      return yearComparison
    }
    return reverse ? Number(a.month) - Number(b.month) : Number(b.month) - Number(a.month)
  })
}

class ArrayOfTimeData<T> extends Array<T> {
  get_sum (field: keyof T): number { return this.reduce((sum, it) => { return (typeof it[field] === 'number') ? sum + (it[field] as number) : sum }, 0) }
  get_sums (...fields: Array<keyof T>): number {
    return fields.reduce((sums, field) => {
      return sums + this.get_sum(field)
    }, 0)
  }
}

const isYear = (it: unknown): it is year => {
  if (typeof it === 'number' && it >= 1900 && it <= 2100 && Number.isInteger(it)) {
    return true
  }
  return false
}

const isMonth = (it: unknown): it is month => {
  /**
     * Checks if the given value is a valid month value: an integer between 1 and 12
     * @param it - The value to check.
     * @returns True if the value is a valid month, false otherwise.
     */
  return typeof it === 'number' && it >= 1 && it <= 12 && Number.isInteger(it)
}

const getMonthName = (monthNumber: month): string | undefined => {
  if (typeof monthNumber === 'string' && isMonth(Number(monthNumber))) return getMonthName(Number(monthNumber))
  if (!(isMonth(monthNumber))) throw new TypeError()
  const date = new Date()
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('default', { month: 'long' })
}

/**
 * Filters out instances with non-numeric month and year values.
 *
 * This function takes an array of instances and filters out the instances
 * that have non-numeric month and year values. It returns a new array
 * containing only the instances with valid month and year values.
 *
 * @template T - The type of the instances.
 * @param {T[]} instances - The array of instances to filter.
 * @returns {Array<T & { month: number, year: number }>} - The filtered array of instances.
 */
const filterNonNumericTimes = <T extends Partial<{ year?: number, month?: number }>>(instances: T[], onError: 'ignore' | 'log' | 'raise' = 'ignore'): Array<T & { month: month, year: year }> => {
  return instances.filter(it => {
    const valid = isMonth(it.month) && isYear(it.year)
    if (!valid) {
      if (onError === 'log') console.log('Invalid month or year', it)
      if (onError === 'raise') throw new TypeError('Invalid month or year')
    }
    return valid
  }) as Array<T & { month: month, year: year }>
}

const mapByYearMonth = <
    T extends { year?: year, month?: month },
    RT extends T & { year: year, month: month },
    YT extends TimeDataByYear<RT>,
    MT extends TimeDataByMonth<RT>,
    Arr extends ArrayOfTimeData<RT>
>(
    instances: T[], { Y, M, A, reverse }: { Y?: new () => YT, M?: new () => MT, A?: new () => Arr, reverse?: boolean } = {}) => {
  /*
    This is intended to be a replacement for `groupByYearMonth` which uses a Map rather than an object
    */
  const filtered = filterNonNumericTimes(instances)
  // Note that by default the sort is 'reverse' (descending) so that January is at the top
  const sorted = sortByYearMonth(filtered, reverse ?? true)
  return sorted.reduce<YT>((result, item) => {
    const itemYear = Number(item.year)
    const itemMonth = Number(item.month)
    if (isNaN(itemYear) || isNaN(itemMonth)) throw new TypeError('Input Year / Month invalid')
    if (!(result.has(itemYear))) result.set(itemYear, (typeof M !== 'undefined') ? new M() : new TimeDataByMonth() as MT)
    const resultYear = result.get(itemYear)
    if (typeof resultYear === 'undefined') throw new Error('Set year failed')
    if (!resultYear.has(itemMonth)) resultYear.set(itemMonth, (typeof A !== 'undefined') ? new A() : new ArrayOfTimeData() as Arr)
    const resultMonth = resultYear.get(itemMonth) as unknown as ArrayOfTimeData<RT>
    resultMonth.push(item as RT)
    return result
  }, (typeof Y !== 'undefined') ? new Y() : new TimeDataByYear() as YT)
}

const mapByYearMonthSingle = <T extends Partial<{ year: number, month: number }>>(instances: T[]): Map<year, Map<month, T>> => {
  /*
    This is intended to be a replacement for `groupByYearMonth` which uses a Map rather than an object
    It is for cases where only one instance should exist per month
    */
  const filtered = filterNonNumericTimes(instances)
  const sorted = sortByYearMonth(filtered)
  return sorted.reduce<Map<number, Map<number, T>>>((result, item) => {
    const itemYear = Number(item.year)
    const itemMonth = Number(item.month)
    if (!result.has(itemYear)) result.set(itemYear, new Map())
    result.get(itemYear)?.set(itemMonth, item)
    return result
  }, new Map())
}

class TimeDataByMonth<T extends { month: month, year: year }> extends Map<month, ArrayOfTimeData<T>> {
  get_sum (field: keyof T): number { return Array.from(this.values()).reduce((sum, it) => { return sum + it.get_sum(field) }, 0) }

  get_sums (...fields: Array<keyof T>): number {
    return fields.reduce((sums, field) => {
      return sums + this.get_sum(field)
    }, 0)
  }
}

class TimeDataByYear<T extends { month: month, year: year }> extends Map<year, TimeDataByMonth<T>> {
  get_sum (field: keyof T): number { return Array.from(this.values()).reduce((sum, it) => { return sum + it.get_sum(field) }, 0) }

  get_sums (...fields: Array<keyof T>): number {
    return fields.reduce((sums, field) => {
      return sums + this.get_sum(field)
    }, 0)
  }
}

const groupByYearMonth = <T extends Partial<{ year: number, month: number }>>(instances: T[]) => {
  return instances.reduce<Record<string, { year: string, data: Record<string, T[]> }>>((result, item) => {
    if (typeof item.year !== 'number' || typeof item.month !== 'number') return result
    const yearKey = item?.year.toString()
    const monthKey = item.month
    result[yearKey] = result[yearKey] ?? { year: yearKey, data: {} }
    result[yearKey].data[monthKey] = [...(result[yearKey].data[monthKey] ?? []), item]
    return result
  }, {})
}

const tryDate = (maybedate: unknown, fmt = 'd/MM/yyyy'): string => {
  if (maybedate instanceof Date) return format(maybedate, fmt)
  if (typeof maybedate !== 'string') return ''
  try {
    const reallyDate = new Date(maybedate)
    return format(reallyDate, fmt)
  } catch {
    return ''
  }
}

const formatDateRange = (startDate: unknown, endDate: unknown, fmt = 'd/MM/yyyy'): string => {
  return `${tryDate(startDate)} - ${tryDate(endDate)}`
}

export { sortByYearMonth, mapByYearMonth, filterNonNumericTimes, TimeDataByMonth, TimeDataByYear, ArrayOfTimeData, getMonthName, groupByYearMonth, mapByYearMonthSingle, isMonth, tryDate, formatDateRange }
export type { year, month }
