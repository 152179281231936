import { toRaw, isRef, computed } from 'vue'
import { FileUploadValueType } from '../_types/components/commons/form'
import { Md5 } from 'ts-md5'
import { NestedObject } from '../_types/commons'
import { OptionsType } from '../_types/components/forms/select-dropdown'
import { parse, differenceInYears, differenceInDays, differenceInMonths, differenceInWeeks } from 'date-fns'
import { getMonthName } from './timedata.helpers'
import { FeatureSchema } from '../stores/projectmanager'

const getCookie = (name: string): string => {
  let cookieValue = 'notset'
  if (typeof document !== 'undefined' && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}

const getCsrf = () => {
  return getCookie('csrftoken') ?? ''
}

const objectToBlob = (file: object) => {
  return new Blob([JSON.stringify(file)])
}

const strCapitalized = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

async function readFileAsync (file: File): Promise<FileUploadValueType> {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve({
        thumbnail: reader.result as string,
        name: file.name
      })
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

const arrayToMap = <T extends { id: number }>(array: T[]): Map<string, T> => {
  const map = new Map<string, T>()
  array.forEach(item => map.set(item.id.toString(), toRaw(item)))
  return map
}

const mapToArray = <K, V> (map: Map<K, V>): V[] => {
  const array: V[] = []
  map.forEach((value, key) => {
    array.push(toRaw(value))
  })
  return array
}

const base64ToFile = (base64: string, name: string): File => {
  const parts = base64.split(',')
  const mimeTypeRegex = /^data:(.*?);base64$/
  const matches = parts[0].match(mimeTypeRegex)
  const mimeType = matches?.[1]
  if (mimeType === undefined) {
    throw new Error('Invalid base64 string')
  }
  const b64 = atob(parts[1])
  const len = b64.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = b64.charCodeAt(i)
  }
  return new File([bytes.buffer], name, { type: mimeType })
}

const objectToFormData = (object: object): FormData => {
  const formData = new FormData()
  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value ?? '')
  })
  return formData
}

const generateMonthsAsOptions = (): OptionsType => {
  const monthNames = []
  const date = new Date()
  for (let i = 0; i < 12; i++) {
    date.setMonth(i)
    monthNames.push({
      value: i + 1,
      label: date.toLocaleString('en-us', { month: 'long' })
    })
  }
  return monthNames
}

const generateYears = (startYear: number, endYear?: number): number[] => {
  const currentYear = new Date().getFullYear()
  endYear = endYear !== undefined ? endYear : currentYear
  const years = []
  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}
/**
 * Ported from https://raw.githubusercontent.com/cyberphone/json-canonicalization/master/node-es6/canonicalize.js
 * @param object
 * @returns
 */
const canonicalize = (object: unknown) => {
  let buffer = ''
  serialize(object)
  return buffer

  function serialize (object: unknown) {
    if (object === null || typeof object !== 'object' || Object.prototype.hasOwnProperty.call(object, 'toJSON')) {
      // Primitive type or toJSON - Use ES6/JSON
      buffer += JSON.stringify(object)
    } else if (Array.isArray(object)) {
      // Array - Maintain element order
      buffer += '['
      let next = false
      object.forEach((element) => {
        if (next) buffer += ','
        next = true
        // Array element - Recursive expansion
        serialize(element)
      })
      buffer += ']'
    } else {
      // Object - Sort properties before serializing
      buffer += '{'
      let next = false
      const entries = Object.entries(object).sort((a, b) => b[0].localeCompare(a[0]))
      entries.forEach((key, value) => {
        if (next) buffer += ','
        next = true
        // Property names are strings - Use ES6/JSON
        buffer += JSON.stringify(key)
        buffer += ':'
        // Property value - Recursive expansion
        serialize(value)
      })
      buffer += '}'
    }
  }
}

/**
 * Returns a SHA-256 encoded string value
 * @param msg The string to be encoded
 * @param len The bytes to use in comparison
 * @returns Promise of a Hash value
 */
function hashString (msg: string, len?: number) {
  return Md5.hashStr(msg)
}

/**
 * Method to consistently hash a JSON object, depends on the
 * `canonicalize` library imported above
 * @param msg Any JSON stringifiable object
 * @returns string
 */
function hashObject (msg: object, len?: number) {
  return hashString(canonicalize(msg))
}

const findValueByKey = (obj: any, key: string): any => {
  if (key in obj) {
    return obj[key]
  }

  for (const nestedKey in obj) {
    if (typeof obj[nestedKey] === 'object') {
      const nestedResult = findValueByKey(obj[nestedKey], key)
      if (typeof nestedResult !== 'undefined') {
        return nestedResult
      }
    }
  }

  return null
}

interface DateOptionsType {
  year?: 'numeric' | '2-digit'
  month?: 'numeric' | '2-digit' | 'long'
  day?: 'numeric' | '2-digit'
  hour?: 'numeric' | '2-digit'
  minute?: 'numeric' | '2-digit'
  hour12?: boolean
}

function formatDate (dateString: string, options: DateOptionsType): string {
  const date = new Date(dateString)

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)
  return formattedDate
}

/**
 * Method to find differences between 2 object
 * @param msg Any JSON stringifiable object
 * @param obj1 Record<string, any>
 * @param obj2 Record<string, any>
 * @param currentPath string
 * @param excludeNonExistingField boolean , if its `true` it will return an array if the keys are existing in both object
 * @returns array
 */
const findDifferentFields = (obj1: Record<string, any>, obj2: Record<string, any>, currentPath = '', excludeNonExistingField = false): string[] => {
  const differentFields: string[] = []

  const compareObjects = (obj1: Record<string, any>, obj2: Record<string, any>, currentPath: string): string[] => {
    const nestedDifferences: string[] = []

    for (const key in obj1) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj1.hasOwnProperty(key)) {
        const path = (currentPath.length > 0) ? `${currentPath}.${key}` : key

        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const subDifferences = compareObjects(obj1[key], obj2[key], path)
          nestedDifferences.push(...subDifferences)
        } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            nestedDifferences.push(path)
          }
        } else if (obj1[key] !== obj2[key]) {
          if (excludeNonExistingField) { // exclude the keys if its not existing in both object
            if ((typeof obj1[key] !== 'undefined' && typeof obj2[key] !== 'undefined')) {
              nestedDifferences.push(path)
            }
          } else {
            nestedDifferences.push(path)
          }
        }
      }
    }

    return nestedDifferences
  }

  differentFields.push(...compareObjects(obj1, obj2, currentPath))
  return differentFields
}

const formatCurrencyWithDollar = (number: number | undefined, style: 'decimal' | 'currency' | 'percent' = 'currency', minimumFractionDigits = 2): string => {
  if (typeof number === 'undefined') number = 0
  const formatter = new Intl.NumberFormat('en-US', {
    style,
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits: 2
  })

  return formatter.format(number)
}

const getCurrentDateTimeFileName = (): string => {
  const currentDateTime = new Date()

  const year = currentDateTime.getFullYear()
  const month = String(currentDateTime.getMonth() + 1).padStart(2, '0')
  const day = String(currentDateTime.getDate()).padStart(2, '0')
  const hour = String(currentDateTime.getHours()).padStart(2, '0')
  const minute = String(currentDateTime.getMinutes()).padStart(2, '0')
  const second = String(currentDateTime.getSeconds()).padStart(2, '0')

  const fileName = `${year}-${month}-${day}_${hour}-${minute}-${second}`

  return fileName
}

/**
 * unwrap nested reactive object, will remove the proxy data
 * @param obj object
 * @returns object
 */
const unwrapNested = <T>(obj: T): T => {
  if (typeof obj !== 'object') {
    return obj
  }

  // if object is an array
  if (Array.isArray(obj)) {
    return obj.map((item) => unwrapNested(item)) as unknown as T
  }

  // If the object is a reactive reference, unwrap it using toRaw
  if (isRef(obj) && typeof obj.value === 'object') {
    return toRaw(obj.value) as unknown as T
  }

  // If the object is a regular object, recursively unwrap each property
  const unwrappedObj: Record<string, any> = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      unwrappedObj[key] = unwrapNested(obj[key])
    }
  }

  return unwrappedObj as T
}

const sanitizeString = (str: string) => {
  return str.replace(/,/g, '')
}

const formatNumberWithCommas = (value: string): string => {
  const val = sanitizeString(value)
  const parts = val.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

const stringToMonthYear = (month: number, year: number) => {
  const date = new Date(year, month - 1) // Subtract 1 from the month since it is zero-based in JavaScript Date objects
  const monthYearString = date.toLocaleString('en-US', { month: 'long', year: 'numeric' })
  return monthYearString
}

// Function to format a number by adding commas every three digits
const formatNumberToCurrency = (value: string): string => {
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const currencyToNumber = (string: string): number => {
  return parseFloat(string.replace(/[^0-9.]/g, ''))
}

/**
 * extract field name based on history api result
 * @param obj NestedObject
 * @returns stringp
 */
const extractFieldNames = (obj: NestedObject): string[] => {
  const fieldNames: string[] = []
  for (const key in obj) {
    const value = obj[key]
    if (Array.isArray(value)) {
      // If the value is an array, handle each item in the array separately
      value.forEach((item) => {
        if (typeof item === 'object' && item !== null) {
          const nestedFieldNames = extractFieldNames(item)
          fieldNames.push(...nestedFieldNames)
        }
      })
    } else if (typeof value === 'object' && value !== null) {
      // If the value is an object, recursively extract field names from it
      const nestedFieldNames = extractFieldNames(value)
      fieldNames.push(...nestedFieldNames)
    } else {
      fieldNames.push(key)
    }
  }
  return [...new Set(fieldNames)]
}

const isDateInRange = (currentDate: string, startDate: string, finishDate: string): boolean => {
  const currentTimestamp = Date.parse(currentDate)
  const startTimestamp = Date.parse(startDate)
  const finishTimestamp = Date.parse(finishDate)

  return currentTimestamp >= startTimestamp && currentTimestamp <= finishTimestamp
}

const selectOptions = computed(() => [
  {
    label: 'Tetun',
    value: 'tet'
  },
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Portuguese',
    value: 'pt'
  }
])

// https://stackoverflow.com/questions/71075490/how-to-make-a-structuredclone-of-a-proxy-object?noredirect=1&lq=1
const toRawDeep = function<T> (observed: T): T {
  const val = toRaw(observed)
  if (Array.isArray(val)) return val.map(toRawDeep) as T
  if (val === null) return null as T
  if (typeof val === 'object') {
    const entries = Object.entries(val).map(([key, val]) => [key, toRawDeep(val)])
    return Object.fromEntries(entries)
  }
  return val
}

const generateId = (len?: number): string => {
  const dec2hex = (dec: number): string => dec.toString(16).padStart(2, '0')
  const arr = new Uint8Array((len ?? 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}
/**
 * Represents the difference between two dates in a specific unit.
 */
interface DateDiff { unit: 'years' | 'months' | 'weeks' | 'days', value: number }

/**
 * Calculates the difference between two dates in terms of years, months, weeks, and days.
 * @param inputDate - The input date in the format 'yyyy-MM-dd'.
 * @param endDate - Optional. The end date in the format 'yyyy-MM-dd'. If not provided, the current date is used.
 * @returns An array of objects representing the non-zero differences between the dates.
 */
const extractDateDiff = (inputDate: string, endDate?: string): DateDiff[] => {
  const inputDateObj = parse(inputDate, 'yyyy-MM-dd', new Date())
  const endDateObj = endDate !== undefined ? parse(endDate, 'yyyy-MM-dd', new Date()) : new Date()

  const years = Math.abs(differenceInYears(endDateObj, inputDateObj))
  const months = Math.abs(differenceInMonths(endDateObj, inputDateObj))
  const weeks = Math.abs(differenceInWeeks(endDateObj, inputDateObj))
  const days = Math.abs(differenceInDays(endDateObj, inputDateObj))

  const remainingMonths = months % 12

  const remainingWeeks = weeks % 4

  const remainingDays = days % 7

  const results: DateDiff[] = [
    { unit: 'years', value: years },
    { unit: 'months', value: remainingMonths },
    { unit: 'weeks', value: remainingWeeks },
    { unit: 'days', value: remainingDays }
  ]

  return results.filter(({ value }) => value > 0)
}

function removeSlashFromString (str: string) {
  return str.replace(/\//g, '')
}

/**
 * generateProjectAsTableItem
 * generate new array object to display project as table item
 * @param items FeatureSchema[]
 * @param hasRedirectParams boolean , if its `true` it will include redirectParams on the result
 * @returns ProjectItemSchema
 */
interface ProjectItemSchema {
  project_id: number
  project_name?: number
  project_activity?: number
  last_progress: string
  total_expenditures: string
  total_budget_allocated: string
  balance: string
  redirectParams?: Record<string, string | number> | undefined
}

function generateProjectAsTableItem (items: FeatureSchema[], hasRedirectParams = false): ProjectItemSchema[] {
  return items.map(feature => {
    const totalExpenditures = Number(feature.properties.materials_exp_sum ?? 0) + Number(feature.properties.labour_exp_sum ?? 0)
    const totalBudgetAllocated = Number(feature.properties.labour_incentive_sum ?? 0) + Number(feature.properties.materials_sum ?? 0)
    const redirectParams = hasRedirectParams
      ? {
          projectId: feature.properties.project_id
        }
      : undefined
    return {
      project_id: feature.properties.project_id,
      project_name: feature.properties.output,
      project_activity: feature.properties.cycle,
      last_progress: `${feature.properties.project_completion ?? 0}%`,
      project_status: feature.properties.project_status,
      total_expenditures: formatCurrencyWithDollar(totalExpenditures),
      total_budget_allocated: formatCurrencyWithDollar(totalBudgetAllocated),
      balance: formatCurrencyWithDollar(totalBudgetAllocated - totalExpenditures),
      redirectParams
    }
  })
}

/**
 * Function to remove $gettext wrapper and double quotes from a string
 * @param str string
 * @returns string
 */
function removeFormKitWrapper (str: string): string {
  // Regular expression to match $gettext("name"), double quotes, and "repeater"
  return str.replace(/\$gettext\((.*?)\)|"|repeater/g, '$1').replace(/"/g, '')
}

export type {
  DateDiff
}

export {
  selectOptions,
  toRawDeep,
  getCsrf,
  objectToBlob,
  strCapitalized,
  readFileAsync,
  arrayToMap,
  mapToArray,
  base64ToFile,
  objectToFormData,
  generateMonthsAsOptions,
  generateYears,
  canonicalize,
  hashString,
  hashObject,
  findValueByKey,
  formatDate,
  findDifferentFields,
  formatCurrencyWithDollar,
  getCurrentDateTimeFileName,
  unwrapNested,
  sanitizeString,
  formatNumberWithCommas,
  stringToMonthYear,
  formatNumberToCurrency,
  getMonthName,
  currencyToNumber,
  extractFieldNames,
  isDateInRange,
  generateId,
  extractDateDiff,
  removeSlashFromString,
  generateProjectAsTableItem,
  removeFormKitWrapper
}
